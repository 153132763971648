import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VlStorageService {
  private _data: IStorageData = { apitoken: null };
  private key: string = 'vl-Data';

  set data(value: IStorageData) {
    localStorage.setItem('vl-data', JSON.stringify(value));
    this._data = value;
  }

  get data() {
    // Return a object clone
    return { ...this._data };
  }

  constructor() {}

  setRoute(route: string) {
    const data = this.data;
    if (!data.current) data.current = {};
    data.current.view = route;
    this.data = data;
  }

  saveContact(user: IUserInfo) {
    this.data.user = user;
  }
}
