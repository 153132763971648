import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScheduleComponent } from './views/schedule/schedule.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TimerComponent } from './views/timer/timer.component';
import { TermsComponent } from './modals/terms/terms.component';
// import { EvaluationComponent } from './modals/evaluation/evaluation.component';
import { EvaluationComponent as ev2 } from './views/evaluation/evaluation.component';
import { GreetingComponent } from './views/greeting/greeting.component';
import { LoadingComponent } from './modals/loading/loading.component';
import { AMPMPipe } from './core/pipes/ampm.pipe';
import { SanitaizerPipe } from './core/pipes/sanitaizer.pipe';
import { TimeSelectorComponent } from './views/schedule/time-selector/time-selector.component';
import { ContactComponent } from './views/contact/contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './core/interceptors/auth-interceptor.service';
import { ScheduleDoneComponent } from './modals/schedule-done/schedule-done.component';
import { NotfoundComponent } from './views/notfound/notfound.component';
import { ChatComponent } from './views/chat/chat.component';
import { MsgComponent } from './views/chat/msg/msg.component';
import { InputComponent } from './views/chat/input/input.component';
import { HistoryComponent } from './views/chat/history/history.component';
import { OnlineComponent } from './views/chat/online/online.component';
import { UseridPipe } from './core/pipes/userid.pipe';
import { NotifyComponent } from './views/chat/notify/notify.component';
import { FileInputDirective } from './file-upload/file-input.directive';
import { ToastrModule } from 'ngx-toastr';
import { AwatingConnectComponent } from './views/chat/awating-connect/awating-connect.component';
import { AgentDisconnectComponent } from './views/chat/agent-disconnect/agent-disconnect.component';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  declarations: [
    AppComponent,
    ScheduleComponent,
    TimerComponent,
    TermsComponent,
    ev2,
    GreetingComponent,
    LoadingComponent,
    AMPMPipe,
    SanitaizerPipe,
    TimeSelectorComponent,
    ContactComponent,
    ScheduleDoneComponent,
    NotfoundComponent,
    ChatComponent,
    MsgComponent,
    InputComponent,
    HistoryComponent,
    OnlineComponent,
    UseridPipe,
    NotifyComponent,
    FileInputDirective,
    AwatingConnectComponent,
    AgentDisconnectComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDialogModule,
    MatRadioModule,
    MatListModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    ToastrModule.forRoot(),
    CodeInputModule,
    MatCheckboxModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  entryComponents: [TermsComponent, LoadingComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    const el = createCustomElement(AppComponent, {
      injector: this.injector,
    });
    customElements.define('data-videolink', el);
  }
  ngDoBootstrap() {}
}
