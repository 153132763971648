<app-online></app-online>
<app-history
  #chat
  [newMsg]="newMessage"
  [newNotify]="newNotify"
  [newFile]="newFile"
></app-history>
<!-- Input -->
<app-input (new)="newMsg($event)" (newFile)="upload($event)"></app-input>
<!-- <iframe
  *ngIf="url"
  [src]="url | sanitaizer"
  style="height: 0px; opacity: 0"
></iframe> -->
<audio #notify src="../../assets/sounds/notify2.mp3" id="notify"></audio>
