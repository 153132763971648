<div class="hour-selector-cont">
  <mat-list>
    <mat-list-item
      *ngFor="let ele of intervals"
      [ngClass]="{ selected: ele.selected, disabled: ele.len > 2 }"
      (click)="selectElement($event, ele)"
      >{{ ele.start + " - " + ele.end }}</mat-list-item
    >
  </mat-list>
</div>
