import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-awating-connect',
  templateUrl: './awating-connect.component.html',
  styleUrls: ['./awating-connect.component.scss'],
})
export class AwatingConnectComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AwatingConnectComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) {}

  ngOnInit(): void {}
}
