<header class="header">
  <h2 class="title">Evaluación</h2>
  <p class="description">Cuéntanos como estuvo tu experiencia con nosotros</p>
</header>

<form [formGroup]="form" class="form" (ngSubmit)="send()">
  <mat-radio-group (change)="evaluate($event)">
    <div class="cont">
      <div *ngFor="let item of range(0, 10)" class="buttons">
        <mat-radio-button color="primary" [value]="item"></mat-radio-button>
        <span class="label">{{ item }}</span>
      </div>
    </div>
  </mat-radio-group>

  <mat-form-field [appearance]="'outline'">
    <mat-label>Ayúdanos a mejorar. Cuéntanos un poco tu experiencia</mat-label>
    <textarea matInput formControlName="text"></textarea>
    <mat-error *ngIf="getControl('text').hasError('minlength')">
      Texto mínimo de 20 caracteres.
    </mat-error>
    <mat-error *ngIf="getControl('text').hasError('maxlength')">
      Máximo de caracteres de 512.
    </mat-error>
  </mat-form-field>

  <button type="submit" mat-raised-button [disabled]="form.invalid || !value">
    Enviar evaluación
  </button>
</form>
