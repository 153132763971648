<div
  class="vl-msg"
  [ngClass]="{ 'vl-msg__right': !left, 'vl-msg__left': left }"
>
  <div class="vl-msg-header" *ngIf="left">
    {{ msg.sender | userid | async }}
  </div>
  <div class="vl-msg-body" *ngIf="msg.type == 'text'">{{ msg.cont }}</div>
  <div class="vl-msg-body" *ngIf="msg.type === 'file'">
    <a [href]="cdnAddress + '/c/' + msg.cont.id" target="_blank">{{
      msg.cont.name
    }}</a>
  </div>
  <div class="vl-msg-body" *ngIf="msg.type === 'link'">
    Se ha iniciado una video llamada con el agente, si tienes problemas para
    iniciar puedes hacer click
    <a [href]="msg.link" target="_blank"> Aquí </a>
  </div>
  <div class="vl-msg-footer">{{ msg.date | date: "HH:mm a" }}</div>
</div>
