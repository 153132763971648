<ng-container *ngFor="let msg of msgs">
  <app-msg *ngIf="!msg.notify" [msg]="msg" [left]="!msg.self"></app-msg>
  <app-notify *ngIf="msg.notify" [type]="msg.type" [msg]="msg"></app-notify>
  <p class="loading-file" [ngClass]="{ active: uploadingFile }">
    Cargando archivo {{ percent }}%
  </p>
  <p class="error-file" [ngClass]="{ active: fileError }">
    {{ msgError }}
  </p>
</ng-container>
