import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PrefService {
  preferences: any;
  constructor(private api: ApiService) {}
  getPreferences() {
    return this.api.get('/ap/pref').toPromise();
  }
}
