import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { InternalRouterService } from 'src/app/core/route/internal-router.service';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthStorageService } from 'src/app/core/services/auth-storage.service';
import { ScheduleDoneComponent } from 'src/app/modals/schedule-done/schedule-done.component';
import { TermsComponent } from 'src/app/modals/terms/terms.component';
import { LoadingComponent } from 'src/app/modals/loading/loading.component';
import { WsService } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  form: FormGroup;
  appointmentActive: boolean;
  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    private router: InternalRouterService,
    private storage: AuthStorageService,
    private ws: WsService
  ) {
    this.formBuild();
    this.appointmentActive = this.storage.appointmentActive;
  }

  formBuild() {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(120),
        Validators.minLength(2),
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.maxLength(120),
        Validators.minLength(2),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(120),
        Validators.minLength(2),
        Validators.email,
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.maxLength(120),
        Validators.minLength(2),
        Validators.pattern(/[0-9]{10}/),
      ]),
    });
  }

  getControl(controname): AbstractControl {
    return this.form.controls[controname];
  }
  ngOnInit(): void {
    this.ws.init();
  }

  initNow() {
    const { name, lastname, phone, email } = this.form.value;
    sessionStorage.setItem(
      'name',
      JSON.stringify({ name, lastname, phone, email })
    );
    let refTerms = this.dialog.open(TermsComponent, {
      width: '650px',
      data: { email },
    });
    refTerms.beforeClosed().subscribe(
      (data) => {
        if (data) {
          const loadingRef = this.dialog.open(LoadingComponent, {
            width: '500px',
            disableClose: true,
            data: this.form.value,
          });
        }
      },
      (err) => {
        console.log('el error');
      }
    );
  }

  async submit() {
    try {
      if (!this.appointmentActive) {
        return this.initNow();
      }

      const { name, lastname, phone, email } = this.form.value;
      const { date, time } = JSON.parse(localStorage.getItem('date'));
      console.log(moment(date).format('yyyy-MM-DD'));

      const d = await this.api
        .post('/ap/ev/creteEvent', {
          name: `${name} ${lastname}`,
          phone,
          email,
          ability: localStorage.getItem('action'),
          date: moment(date).format('yyyy-MM-DD'),
          startHour: time.start,
          endHour: time.end,
        })
        .toPromise();

      this.dialog
        .open(ScheduleDoneComponent, {
          width: '400px',
          disableClose: true,
        })
        .beforeClosed()
        .subscribe(() => {
          this.router.goTo('');
        });

      console.log(d);
    } catch (e) {
      console.log(e);
    }
  }
}
