import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ampm',
})
export class AMPMPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): String {
    if (value > 11) {
      if (value > 12) value = value - 12;
      return `${value} PM`;
    } else {
      return `${value} AM`;
    }
  }
}
