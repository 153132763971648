import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStorageService } from 'src/app/core/services/auth-storage.service';
import { PrefService } from 'src/app/core/services/pref.service';
import { TokenValidationService } from 'src/app/core/services/token-validation.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  terms: string = '';
  codeValid: boolean = false;
  termsAccept: boolean = false;
  disabled: boolean = true;
  verificationCode: string;
  codeError: string;

  constructor(
    public dialogRef: MatDialogRef<TermsComponent>,
    private storage: AuthStorageService,
    private prefe: PrefService,
    private EmailValidation: TokenValidationService,
    @Inject(MAT_DIALOG_DATA) private data: { email: string }
  ) {
    console.log(this.prefe.preferences);

    if (this.prefe.preferences['chat.terms']) {
      this.terms = this.prefe.preferences['chat.terms'];
      const match = this.terms.match(/\[(?<link>.*?)\]\((?<text>.*?)\)/);
      if (match) {
        this.terms = this.terms.replace(
          match[0],
          `<a target="_blank" href="${match.groups.link}">${match.groups.text}</a>`
        );
      }
    } else {
      this.terms = 'Términos y condiciones no definidos';
    }
  }

  async ngOnInit(): Promise<void> {
    const result = await this.EmailValidation.sendVerify(this.data.email);
    console.log('result email:', result);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onCodeChanged(e: string) {
    this.codeValid = e.length == 4;
    this.verificationCode = e;
    this.codeError = '';
  }
  async onCodeCompleted(e: string) {
    try {
      const result = await this.EmailValidation.confirmCode(
        this.data.email,
        this.verificationCode
      );

      if (result.success) {
        this.codeError = '';
        this.codeValid = true;
      } else {
        this.codeError = 'Código inválido';
        this.codeValid = false;
      }
      console.log(result);
    } catch (e) {
      console.log(e);
    }
  }

  onChangeRadio(e: MatCheckboxChange) {
    this.termsAccept = e.checked;
    console.log(e.checked);
  }
}
