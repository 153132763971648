import { HttpEventType } from '@angular/common/http';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FileUploadConfig, FileUploadProgress } from 'src/@types/file-input';
import { FileUploadService } from '../core/services/file-upload.service';

@Directive({
  selector: '[appFileInput]',
})
export class FileInputDirective implements OnInit {
  @Input('config') config?: FileUploadConfig;
  files: FileList;

  @Output() err: EventEmitter<any> = new EventEmitter<any>();
  @Output('upload') initUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output() progress: EventEmitter<FileUploadProgress> =
    new EventEmitter<FileUploadProgress>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  @Output() events: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private upload: FileUploadService
  ) {}

  ngOnInit() {
    this.el.nativeElement.multiple = this.config.multi;
  }
  /**
   * @event change
   * @description Acciones del evento chance
   */
  @HostListener('change') async changeHandler() {
    try {
      this.files = this.el.nativeElement.files;
      // Valida todos los archivos en la clase
      this.validateFiles(this.files);

      this.upload.fileUpload(`/c/chat`, this.files[0]).subscribe(
        (event) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.init(event);
              break;
            case HttpEventType.UploadProgress:
              this.loading(event);
              break;
            case HttpEventType.Response:
              this.done(event);
              break;
            default:
              // console.log(event);
              break;
          }
        },
        (err) => {
          console.log('Error de subida de archivo', err);
        }
      );
    } catch (e) {
      this.err.emit(e);
    }
  }

  init(event) {
    this.initUpload.emit(this.files[0]);
    this.events.emit({ type: 'init', event });
  }
  done(event) {
    this.success.emit(event);
    this.events.emit({ type: 'done', event });
    this.el.nativeElement.files = undefined;
    this.el.nativeElement.value = '';
  }

  loading(event) {
    const percentDone = Math.round((100 * event.loaded) / (event.total ?? 0));
    this.progress.emit({
      percent: percentDone,
      loaded: event.loaded,
      total: event.total,
    });
    this.events.emit({
      type: 'progress',
      event: {
        percent: percentDone,
        loaded: event.loaded,
        total: event.total,
      },
    });
  }

  /**
   * @description Valida todos los archivos que se le pasan como parametro
   * @param {FileList} files
   */
  validateFiles(files: FileList) {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.validateExt(file);
        this.validateSize(file);
      }
    } catch (e) {
      throw e;
    }
  }

  /**
   * @param {File} file Archivo el cual se validara
   * @description Valida si la extension esta entre las permitidas
   * @returns {File} Retorna true si es valido
   */
  validateExt(file: File): File | Error {
    if (!this.config.ext) return file;
    let x = this.config.ext.includes(this.getExt(file.name));
    if (!x) throw { msg: 'invalid extension', code: 'file/extensionException' };
    return file;
  }

  /**
   * @param {File} file Archivo
   * @returns {File} Retorna true si es valido
   */
  validateSize(file: File): File | Error {
    if (!this.config.maxSize) return file;
    // Tamano maximo permitido por el desarrollador
    const maxSize = this.config.maxSize;
    const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'yb'];
    // Se obtine el valor del tamano
    const value = parseFloat(maxSize);
    // Extraemos el sufijo
    const sub = maxSize.split(value.toString()).pop().toLocaleLowerCase();
    // Posicion donde esta el elemento y lo potencio para calcular los bytes
    const maxSizeValue = value * 1024 ** sizes.indexOf(sub);
    // Error si el tamano es incorrecto
    if (file.size > maxSizeValue)
      throw { msg: 'invalid size', code: 'file/maxSizeException' };
    return file;
  }

  /**
   * @param {string} filename Nombre del Archivo
   * @returns {string} La extension del archivo que se le esta pasando
   */
  getExt(filename): string {
    return filename.split('.').pop();
  }

  uploadFile() {}
}
