import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { InternalRouterService } from 'src/app/core/route/internal-router.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { VlStorageService } from 'src/app/core/services/vl-storage.service';
import { WsService } from 'src/app/core/services/ws.service';
import { AgentDisconnectComponent } from './agent-disconnect/agent-disconnect.component';
import { AwatingConnectComponent } from './awating-connect/awating-connect.component';
import { HistoryComponent } from './history/history.component';

interface Msg {
  sender: String;
  content: {
    text: String;
  };
  date: Date | Number;
}
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('chat') chatHistory: HistoryComponent;
  newMessage: EventEmitter<any> = new EventEmitter<any>();
  newNotify: EventEmitter<any> = new EventEmitter<any>();
  newFile: EventEmitter<any> = new EventEmitter<any>();
  subs: Array<Subscription> = [];
  url: string;
  call_id: string;
  dialogRef: MatDialogRef<any>;
  agentDisconnectDialog: MatDialogRef<AgentDisconnectComponent, any>;
  liveInterval: number | any = 0;

  constructor(
    private ws: WsService,
    private route: InternalRouterService,
    private chatService: ChatService,
    private dataStorage: VlStorageService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.subs[0] = this.ws.listen('message').subscribe((msg: Msg) => {
      this.newMessage.emit(msg);
      (document.getElementById('notify') as HTMLAudioElement)
        .play()
        .then((data) => {
          console.log(data);
        });
    });

    this.subs[1] = this.ws.listen('call').subscribe((data) => {
      this.initCall(data);
      this.url = data.join_url;
      this.call_id = data.call_id;
      this.newMessage.emit({
        type: 'link',
        link: this.url,
      });
    });
    this.subs[2] = this.ws.listen('chat_end').subscribe((data) => {
      this.endChat(data);
    });

    this.subs[3] = this.ws.status.subscribe((status) => {
      if (status == 'connect') {
        // Vuelve a connectar con el chat
        this.reconnect();
        this.toastr.success('Conexión restablecida');
        if (this.dialogRef) this.dialogRef.close();
      } else {
        this.toastr.error('Se perdió la conexión');
        if (this.route.currentRoute === 'chat')
          this.dialogRef = this.dialog.open(AwatingConnectComponent, {
            disableClose: true,
            width: '400px',
          });
      }
    });
    this.subs[4] = this.ws.listen('user_disconnect').subscribe((data) => {
      this.agentDisconnectDialog = this.dialog.open(AgentDisconnectComponent, {
        disableClose: true,
        width: '400px',
      });
    });

    this.subs[5] = this.ws.listen('user_connect').subscribe((data) => {
      if (this.agentDisconnectDialog) this.agentDisconnectDialog.close();
    });

    this.ws.listen('someone').subscribe((data) => {
      console.log('here');
      this.ws.emit('here');
    });
    this.live();
    // await this.reconnect();
  }

  newMsg(ev) {
    this.newMessage.emit(ev);
    this.ws.emit('message', {
      cont: ev,
      type: 'text',
    });
    console.log(ev);
  }
  /**
   * @description Cuando la llamada es iniciada o cuando el ws server informa de su solicitud
   * @param data
   */
  initCall(data) {
    this.newNotify.emit({
      type: 'success',
      cont: 'Se ha iniciado una video llamada. Se abrirá automáticamente Zoom Meeting para conversar con un agente en vivo, pero siempre podrás estar en contacto por aquí',
      notify: true,
    });
    this.url = data.join_url;

    let win = window.open(
      this.url,
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes'
    );
    this.call_id = data.call_id;
  }
  /**
   * @description Acciones que ocurren cuando finalizamos la llamada
   * @param data
   */
  endChat(data) {
    console.log('end');
    clearInterval(this.liveInterval);
    this.liveInterval = 0;
    this.newNotify.emit({
      type: 'warn',
      cont: 'Este chat ha finalizado',
      notify: true,
    });
    setTimeout(() => {
      this.route.goTo('evalu');
    }, 3000);
  }

  ngOnDestroy() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  upload(ev) {
    this.newFile.emit(ev);
  }

  async reconnect() {
    try {
      const chat_id = this.dataStorage.data.chat._id;
      const chat = await this.chatService.getChat(chat_id);
      const guest_id = localStorage.getItem('guest');
      if (chat.status == 'take') {
        let msgs = chat.messages.map((msg) => {
          let m: any = {
            sender: msg.sender,
            date: msg.date,
            type: msg.type,
            self: guest_id === msg.sender,
          };
          if (msg.type === 'text') m.cont = msg.content.text;
          else if (msg.type === 'file') m.cont = msg.content;
          return m;
        });
        this.chatHistory.msgs = msgs;
      }
      this.live();
      this.toastr.info('Chat reconectado');
      this.ws.emit('chat_join', { chat_id });
      this.chatHistory.moveScroll();
      this.ws.emit('someone');
    } catch (e) {
      console.log('error', e);
    }
  }

  live() {
    if (!this.liveInterval)
      this.liveInterval = setInterval(() => {
        this.ws.emit('someone');
      }, 2000);
  }
}
