<textarea
  type="text"
  matInput
  placeholder="Escribe tu mensaje"
  class="vl-chat-input"
  (keydown.enter)="enter($event)"
  [(ngModel)]="textContent"
></textarea>
<label
  class="vl-chat-file vl-center"
  for="file-input"
  matTooltip="Enviar un archivo"
>
  <mat-icon>attach_file</mat-icon>
</label>
<input
  type="file"
  appFileInput
  [config]="{
    ext: ['pdf', 'png', 'jpeg', 'jpg'],
    multi: false,
    maxSize: '10mb',
    autoupload: true
  }"
  (events)="newUpload($event)"
  (progress)="uploadProgress($event)"
  (success)="doneUpload($event)"
  (err)="errorUpload($event)"
  style="display: none"
  id="file-input"
/>
<button
  mat-raised-button
  color="primary"
  class="vl-chat-submit"
  (click)="enter($event)"
  title="Send"
>
  <mat-icon>send</mat-icon>
</button>
