<p
  class="notify-content"
  [ngClass]="{
    'notify-content-info': type == 'info',
    'notify-content-success': type == 'success',
    'notify-content-warn': type == 'warn'
  }"
>
  {{ msg.cont }}
</p>
