import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalendarService } from 'src/app/core/services/calendar.service';

export interface Interval {
  start: String;
  end: String;
  selected?: boolean;
}

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
})
export class TimeSelectorComponent implements OnInit {
  intervals: Array<Interval>;
  initHour: String = '09:00';
  endHour = '18:00';
  interval = 30;

  @Input() changeDate: Observable<any>;
  @Output() selectedChange = new EventEmitter<string>();

  constructor(private calendar: CalendarService) {
    this.intervals = [];
  }

  ngOnInit(): void {
    this.changeDate.subscribe(async (data) => {
      this.selectedChange.emit(undefined);
      console.log('la data');
      const date = data.format('yyyy-MM-DD');
      this.intervals = await this.calendar.getIntervals(date).toPromise();
    });
  }

  selectElement(e, ele) {
    this.intervals.forEach((ele) => (ele.selected = false));
    ele.selected = true;
    const { selected, ...data } = ele;
    this.selectedChange.emit(data);
  }
}
