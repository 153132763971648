import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Output('new') new: EventEmitter<String | File> = new EventEmitter<
    String | File
  >();
  @Output() newFile: EventEmitter<any> = new EventEmitter<any>();
  textContent: string = '';
  constructor() {}

  ngOnInit(): void {}

  enter(ev) {
    ev.preventDefault();
    if (this.textContent.trim() !== '') {
      this.new.emit(this.textContent);
      this.textContent = '';
    }
  }

  /* File upload control */
  newUpload(ev: any) {
    this.newFile.emit(ev);
  }

  uploadProgress(ev) {
    this.newFile.emit({ code: 'upload/progress', ev });
  }

  doneUpload(ev) {
    this.newFile.emit({ code: 'upload/success', ev });
  }
  errorUpload(ev) {
    this.newFile.emit({ code: 'upload/error', ev });
  }
}
