import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { WsService } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit, OnDestroy {
  msgs: any = [
    {
      type: 'info',
      cont: 'Los mensajes de este chat están cifrados de extremo a extremo. Nadie fuera de este chat puede leerlos ni escucharlos.',
      notify: true,
    },
  ];
  percent = 0;
  uploadingFile: boolean = false;
  @Input() newMsg: Subject<any>;
  @Input() newNotify: Subject<any>;
  @Input() newFile: Subject<any>;

  subs$: Array<Subscription> = [];
  msgError: any;
  fileError: boolean;
  constructor(private elementRef: ElementRef, private ws: WsService) {}

  ngOnInit(): void {
    this.subs$[0] = this.newMsg.subscribe((data) => {
      if (typeof data == 'string') {
        this.msgs.push({
          sender: '',
          cont: data,
          type: 'text',
          date: Date.now(),
          self: true,
        });
        // Move
      } else if (data.type == 'file') {
        this.msgs.push({
          sender: data.sender,
          cont: data.content,
          type: data.type,
          date: data.date,
          self: false,
        });
      } else if (data.type == 'link') {
        this.msgs.push({
          type: data.type,
          link: data.link,
          self: false,
        });
      } else {
        this.msgs.push({
          sender: data.sender,
          cont: data.content.text,
          type: data.type,
          date: data.date,
          self: false,
        });
      }
      this.moveScroll();
    });
    this.subs$[1] = this.newNotify.subscribe((data) => {
      this.msgs.push(data);
    });
    this.subs$[2] = this.newFile.subscribe((data: any) => {
      switch (data.type) {
        case 'init':
          this.uploadingFile = true;
          break;
        case 'progress':
          this.percent = data.event.percent;
          break;
        case 'done':
          this.uploadingFile = false;
          console.log('Data de archivo subido', data);
          const { originalname, _id: id } = data.event.body;
          console.log('La data', data.event.body);

          this.msgs.push({
            sender: '',
            cont: {
              id,
              name: originalname,
            },
            type: 'file',
            date: Date.now(),
            self: true,
          });
          /**
           * Guardado de informacion en bd
           */
          this.ws.emit('message', {
            cont: { id, name: originalname },
            type: 'file',
          });
          // Move
          this.moveScroll();
          break;
        default:
          this.fileErrorHandler(data);
          break;
      }
    });
  }

  /**
   * @description Manejador de errores de archivo
   **/
  fileErrorHandler(data) {
    // En caso de que el error no sea controlado se retorna
    if (data.code !== 'upload/error') return 'Otro evento';
    // Mensaje de error que se mostraa cuando el usuario pase un archivo con cataracteristicas invalidas
    this.msgError = {
      'file/extensionException': 'Extensión de archivo no aceptada',
      'file/maxSizeException': 'Este documento supera el límite de 10MB',
    }[data.ev.code];
    // Muestra el error en pantalla
    this.showError();
  }

  /**
   * @description Muestra el error en pantalla
   * @param {number} seconds Determina la cantidad de segundos que durara
   */
  showError(seconds: number = 4): void {
    this.fileError = true;
    setTimeout(() => {
      this.fileError = false;
    }, seconds * 1000);
  }

  // Código avanzado solo para genios
  moveScroll() {
    setTimeout(() => {
      this.elementRef.nativeElement.scrollTo(
        0,
        this.elementRef.nativeElement.scrollHeight
      );
    });
  }

  ngOnDestroy() {
    for (const sub of this.subs$) {
      sub.unsubscribe();
    }
  }
}
