<div class="container">
  <div class="card">
    <ng-container [ngSwitch]="route.currentRoute">
      <app-greeting *ngSwitchCase="''" [apiKey]="tokenInfo"></app-greeting>
      <app-schedule *ngSwitchCase="'schedule'"></app-schedule>
      <app-timer *ngSwitchCase="'timer'"></app-timer>
      <app-contact *ngSwitchCase="'contact'"></app-contact>
      <app-notfound *ngSwitchCase="'notfound'"></app-notfound>
      <app-chat *ngSwitchCase="'chat'"></app-chat>
      <app-evaluation-v *ngSwitchCase="'evalu'"></app-evaluation-v>
    </ng-container>
  </div>
</div>
