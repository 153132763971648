import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { InternalRouterService } from 'src/app/core/route/internal-router.service';
import { ApiService } from 'src/app/core/services/api.service';
import { WsService } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-evaluation-v',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
})
export class EvaluationComponent implements OnInit {
  constructor(
    private _ws: WsService,
    private route: InternalRouterService,
    private api: ApiService
  ) {}
  form: FormGroup;
  /**
   * @var {number} value
   * @description valor de la evaluacion
   */
  value: number = 0;

  ngOnInit(): void {
    this.form = new FormGroup({
      text: new FormControl('', [
        Validators.minLength(20),
        Validators.maxLength(512),
      ]),
    });
  }

  range(init: number, end: number): number[] {
    let result: number[] = [];
    for (let i = init; i <= end; i++) {
      result.push(i);
    }
    return result;
  }

  /**
   * @description Captura los datos cuando el radio group cambia su estado
   * @param  ev evento de cambio de angular
   */
  evaluate(ev) {
    this.value = ev.value;
  }

  async send() {
    this._ws.emit('eval', { value: this.value, ...this.form.value });
    this.api
      .post('/ap/evaluate', {
        value: this.value,
        ...this.form.value,
        chat_id: localStorage.getItem('chat_id'),
      })
      .toPromise();

    localStorage.removeItem('chat_id');
    this.route.goTo('');
  }
  getControl(str: string): AbstractControl {
    return this.form.controls[str];
  }
}
