<div class="header">
  Hola, {{ name | uppercase }}, usted tiene <br />
  agendada ninguna cita en este momento.
</div>
<div class="countdown">
  <h2 class="countdown-hour" *ngIf="!no_hour">
    {{ hr + ":" + mn + ":" + sc }}
  </h2>
  <p class="countdown-date" *ngIf="no_hour">Tu cita es:</p>
  <h2 class="countdown-hour" *ngIf="no_hour">{{ fecha }}</h2>
  <p class="countdown-date" *ngIf="!no_hour">{{ fecha }}</p>
</div>
<div class="btn-cont">
  <button class="btn-ingresar" [disabled]="!is_time" (click)="openDialog()">
    Ingresar
  </button>
</div>

<div class="line"></div>

<p class="remenber">
  Recuerde que la cita se realizará a través de Zoom Meeting. Le recomendamos
  crear su cuenta y desc&aacute;rgalo previo a la cita.
</p>

<p class="zoom">
  <img
    src="../../../assets/img/zoom.png"
    alt="zoom-icon"
    style="display: inline; width: 2.5em"
  />
  Si no tienes Zoom Meeting instalado,
  <a href="https://zoom.us/download#client_4meeting  ">
    desc&aacute;rgalo aquí</a
  >
</p>
