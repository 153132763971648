import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InternalRouterService } from 'src/app/core/route/internal-router.service';
import { AbilitiesService } from 'src/app/core/services/abilities.service';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthStorageService } from 'src/app/core/services/auth-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { GroupsService } from 'src/app/core/services/groups.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { PrefService } from 'src/app/core/services/pref.service';
import { VlStorageService } from 'src/app/core/services/vl-storage.service';
import { WsService } from 'src/app/core/services/ws.service';
import { TermsComponent } from 'src/app/modals/terms/terms.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.scss'],
})
export class GreetingComponent implements OnInit {
  name: string = 'Diogo Armando Torrealba';
  user: {
    id: String;
    name: String;
    email: String;
  };
  appointmentActive: boolean;
  abilities: Array<any>;
  api_key = environment.api_token;
  userInfo: any;

  @Input() apiKey;
  constructor(
    public route: InternalRouterService,
    private storage: AuthStorageService,
    private ability: AbilitiesService,
    private prefe: PrefService,
    private dataStorage: VlStorageService,
    private auth: AuthService,
    private chatService: ChatService,
    private ws: WsService,
    private dialog: MatDialog,
    private _group: GroupsService,
    private ls: LocalstorageService
  ) {
    let d = sessionStorage.getItem('user_info');
    if (d) this.user = JSON.parse(d);
  }

  pref: any;
  async ngOnInit() {
    try {
      this.storage.API_KEY =
        (this.dataStorage.data.apitoken ?? this.apiKey) || this.api_key;

      const data = await this.auth.login().toPromise();
      const { token } = data;
      if (this.userInfo)
        sessionStorage.setItem('user_info', JSON.stringify(this.userInfo));
      this.storage.setToken(token);

      const group = await this.getGroupInfo();

      this.abilities = await this.ability.getAbilities();
      this.dataStorage.data.abilities = this.abilities;
      this.pref = this.storage.getInfo().preferences;
      const l = await this.prefe.getPreferences();
      this.prefe.preferences = l.preferences;

      this.appointmentActive = this.pref && this.pref['appointments'];
      this.storage.preferences = this.pref;
      this.storage.appointmentActive = this.appointmentActive;

      const chat_id = localStorage.getItem('chat_id');
      if (chat_id) {
        this.reconnect(chat_id);
      }
    } catch (e) {
      console.log(e);
      this.route.goTo('notfound');
    }
  }

  async getGroupInfo() {
    try {
      // Get groups validation
      const group = this.route.getQuery('gr');
      console.log('group', group);

      let groupInfo;
      if (!group || group.length != 24) {
        console.log('primero');

        groupInfo = await this._group.getHeadQuarter();
        this.route.setQueryParam('gr', groupInfo._id);
      } else {
        console.log('segundo');

        groupInfo = await this._group.getGroupInfo(group);
      }
    } catch (e) {
      if (e.code === 'invaid/group') {
        if (this.route.getQuery('gr') == e.redirect)
          return console.log('grupo por defecto');

        if (e.status === 301) {
          console.log('esta app no pertenece a este grupo');
          return this.route.setQueryParam('gr', e.redirect);
        }
      }
      console.log(e);
    }
  }

  goto(action: IAbilities) {
    localStorage.setItem('action', action.name);
    this.dataStorage.data = {
      ...this.dataStorage.data,
      current: { ability: action },
    };
    if (this.appointmentActive) this.route.goTo('schedule');
    else this.route.goTo('contact');
  }

  async reconnect(chat_id: string) {
    if (!chat_id) return;
    try {
      const chat = await this.chatService.getChat(chat_id);
      if (chat.status == 'take') {
        this.dataStorage.data = { chat: chat, ...this.dataStorage.data };
        this.ws.init();
        this.route.goTo('chat');
      }
    } catch (e) {
      console.log('error', e);
    }
  }
}
