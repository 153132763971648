import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-msg',
  templateUrl: './msg.component.html',
  styleUrls: ['./msg.component.scss'],
})
export class MsgComponent implements OnInit {
  @Input() msg: {
    sender: string;
    date: Date;
    cont: string | any;
    type: 'file' | 'text' | 'icon' | 'link';
    link?: string;
  };
  cdnAddress = environment.storage;

  @Input() left: boolean = true;
  constructor() {}

  ngOnInit(): void {
    console.log('el msg', this.msg);
  }
}
