import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
import { InternalRouterService } from 'src/app/core/route/internal-router.service';
import { AuthStorageService } from 'src/app/core/services/auth-storage.service';
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  @ViewChild('calendar') calendar: MatCalendar<Moment>;
  changeDateSelection: EventEmitter<any> = new EventEmitter();
  /* Date data */
  appointmentHour: any;
  appointmentDate: Moment;
  /* Calendar config */
  selectedDate: Moment;
  minDate: any;
  maxDate: any;
  intervals: Array<any>;
  preferences: any;
  constructor(
    public router: InternalRouterService,
    private storage: AuthStorageService
  ) {
    this.setCalendar();
    this.preferences = this.storage.getInfo().preferences;
    console.log(this.preferences);
  }

  ngOnInit(): void {}

  setCalendar() {
    this.minDate = moment(Date.now());
    this.maxDate = moment(this.minDate).add(2, 'M');
  }
  /**
   *
   * @param date Fecha que fue seleccionada en el calendario
   */
  dateChanged(date: Moment) {
    console.log(`Selected: ${date}`);
    this.appointmentDate = date;
    this.changeDateSelection.emit(date);
  }

  timeChanged(time) {
    console.log(time);
    this.appointmentHour = time;
  }
  /**
   * @description Esta funcion devuelve los estilos al calendario, apra habilitar o deshabilitar dias
   *
   */
  dateClass() {
    return (date) => {
      const d = moment(date).locale('en').format('dddd').toLocaleLowerCase();
      if (!this.preferences['appointments.workdays'].includes(d)) {
        return 'calendar-disable-date';
      }
    };
  }

  continue() {
    localStorage.setItem(
      'date',
      JSON.stringify({ date: this.appointmentDate, time: this.appointmentHour })
    );
    this.router.goTo('contact');
  }
}
