import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private api: ApiService) {}

  getChat(chat_id: string): Promise<any> {
    return this.api.get(`/ap/chat/${chat_id}`).toPromise();
  }
}
