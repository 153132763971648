import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss'],
})
export class OnlineComponent implements OnInit {
  name: string = '';
  constructor() {}

  ngOnInit(): void {
    const data = JSON.parse(sessionStorage.getItem('name'));
    this.name = `${data.name} ${data.lastname}`;
  }
}
