<div class="cont">
  <mat-icon class="vl-terms-close" (click)="onNoClick()">close</mat-icon>
  <!-- <h1 mat-dialog-title>Términos y condiciones</h1> -->
  <h3 mat-dialog-title>Ingresa el código de verificación</h3>
  <div class="vl-code-container">
    <p class="vl-code-label">Recibiste un número de 4 dígitos en tu correo.</p>
    <code-input
      class="vl-code-input"
      [isCodeHidden]="false"
      [codeLength]="4"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </code-input>
    <p class="vl-code-error">{{ codeError }}</p>
  </div>
  <div class="vl-terms-cont">
    <div mat-dialog-content [innerHTML]="terms"></div>
    <mat-checkbox
      color="primary"
      style="margin-top: 10px"
      (change)="onChangeRadio($event)"
      >Acepto términos y condiciones</mat-checkbox
    >
  </div>
  <div mat-dialog-actions>
    <!-- <button mat-button (click)="onNoClick()">
      No acepto los términos y condiciones
    </button> -->
    <button
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      [disabled]="!(codeValid && termsAccept)"
    >
      Continuar
    </button>
  </div>
</div>
