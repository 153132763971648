import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InternalRouterService } from 'src/app/core/route/internal-router.service';

@Component({
  selector: 'app-schedule-done',
  templateUrl: './schedule-done.component.html',
  styleUrls: ['./schedule-done.component.scss'],
})
export class ScheduleDoneComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ScheduleDoneComponent>,
    private route: InternalRouterService
  ) {}

  ngOnInit(): void {}
}
