import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private api: ApiService) {}

  getIntervals(date) {
    return this.api.get(
      '/ap/cal/getintervals',
      new HttpParams({
        fromObject: {
          date,
        },
      })
    );
  }
}
