<div class="container">
  <div class="header">
    <h2 class="title">Datos de contacto</h2>
    <p class="description">
      Para iniciar una llamada requerimos su información personal de contacto
    </p>
  </div>

  <div class="form">
    <form [formGroup]="form" (submit)="submit()">
      <mat-form-field>
        <mat-label for="name">Nombre</mat-label>
        <input type="text" matInput formControlName="name" />
        <mat-error *ngIf="getControl('name').hasError('maxlength')"
          >Nombre muy largo</mat-error
        >
        <mat-error *ngIf="getControl('name').hasError('minlength')"
          >Nombre largo corto</mat-error
        >
        <mat-error *ngIf="getControl('name').hasError('pattern')"
          >Formato de nombre incorrecto</mat-error
        >
        <mat-error *ngIf="getControl('name').hasError('required')"
          >El nombre es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label for="lastname">Apellido</mat-label>
        <input type="text" matInput formControlName="lastname" />
        <mat-error *ngIf="getControl('lastname').hasError('maxlength')"
          >Apellido muy largo</mat-error
        >
        <mat-error *ngIf="getControl('lastname').hasError('minlength')"
          >Apellido largo corto</mat-error
        >
        <mat-error *ngIf="getControl('lastname').hasError('pattern')"
          >Formato de apellido incorrecto</mat-error
        >
        <mat-error *ngIf="getControl('lastname').hasError('required')"
          >El apellido es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label for="email">Correo</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="correo@empresa.com"
        />
        <mat-error *ngIf="getControl('email').hasError('maxlength')"
          >Correo muy largo</mat-error
        >
        <mat-error *ngIf="getControl('email').hasError('minlength')"
          >Correo largo corto</mat-error
        >
        <mat-error *ngIf="getControl('email').hasError('email')"
          >Formato de Correo incorrecto</mat-error
        >
        <mat-error *ngIf="getControl('email').hasError('required')"
          >El Correo es requerido</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <mat-label for="phone">Número celular</mat-label>
        <input
          type="tel"
          matInput
          formControlName="phone"
          placeholder="412-1234321"
        />
        <mat-error *ngIf="getControl('phone').hasError('maxlength')"
          >Número celular muy largo</mat-error
        >
        <mat-error *ngIf="getControl('phone').hasError('minlength')"
          >Número celular largo corto</mat-error
        >
        <mat-error *ngIf="getControl('phone').hasError('pattern')"
          >Formato de número celular incorrecto</mat-error
        >
        <mat-error *ngIf="getControl('phone').hasError('required')"
          >El número celular es requerido</mat-error
        >
      </mat-form-field>
      <div class="btn-cont">
        <button mat-raised-button color="banesco" [disabled]="form.invalid">
          {{ appointmentActive ? "Enviar" : "Ingresar" }}
        </button>
      </div>
    </form>
  </div>
</div>
