import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

interface IGroupInfo {
  _id: string;
  name: string;
  description: string;
}

interface IGroupInfoError {
  code: string;
  msg: string;
  status: number;
  redirect?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  constructor(private api: ApiService) {}

  getGroupInfo(group_id: string): Promise<IGroupInfo | IGroupInfoError> {
    return this.api
      .get(`/ap/gr/${group_id}`)
      .toPromise<IGroupInfo | IGroupInfoError>();
  }

  getHeadQuarter(): Promise<IGroupInfo | IGroupInfoError> {
    return this.api.get('/ap/gr/hq').toPromise<IGroupInfo | IGroupInfoError>();
  }
}
