import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TokenValidationService {
  constructor(private api: ApiService) {}

  sendVerify(email): Promise<any> {
    return this.api
      .post('/ap/validate-token/generate', {
        type: 'standard',
        email,
      })
      .toPromise();
  }

  confirmCode(email: string, token: string): Promise<any> {
    return this.api
      .post('/ap/validate-token', {
        email,
        token,
      })
      .toPromise();
  }
}
