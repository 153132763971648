import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-agent-disconnect',
  templateUrl: './agent-disconnect.component.html',
  styleUrls: ['./agent-disconnect.component.scss']
})
export class AgentDisconnectComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AgentDisconnectComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit(): void {
  }

}
