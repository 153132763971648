import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthStorageService } from '../services/auth-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(private _authService: AuthStorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Obtengo el token actual de la sesion...
    const isFile = req.headers.has('file');
    const token = this._authService.getToken();
    let request = req;

    const headers: any = {
      'Content-Type': 'application/json',
      'x-vl-key': this._authService.API_KEY /* API KEY set on app init */,
    };

    // Validacion de existencia del token para agregar la cabecera...
    if (token) {
      // Modificación del request añadiendo la cabecera de autorizacion de videolink...
      headers.Authorization = `videolink ${token}`;
    }

    if (isFile) {
      delete headers['Content-Type'];
      let he = new HttpHeaders(headers).delete('file');
    }
    request = req.clone({
      setHeaders: headers,
    });

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // Redirección al login en caso de status 401...
        if (err.status === 401) {
          this._authService.clearAll();
        }

        return throwError(err);
      })
    );
  }
}
