import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InternalRouterService } from './core/route/internal-router.service';
import { AuthStorageService } from './core/services/auth-storage.service';
import { AuthService } from './core/services/auth.service';

import { LocalstorageService } from './core/services/localstorage.service';
import { VlStorageService } from './core/services/vl-storage.service';
import { WsService } from './core/services/ws.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Input() name: string;
  @Input() userInfo: any;
  @Input('token') tokenInfo: string;
  interval: number;
  api_key = environment.api_token;
  hasToken: boolean = false;
  constructor(
    public route: InternalRouterService,
    private auth: AuthService,
    private storage: AuthStorageService,
    private ls: LocalstorageService,
    private dataStorage: VlStorageService,
    private ws: WsService
  ) {
    this.interval = setInterval(() => {
      if (window.location.hash == '#timer') {
        this.route.goTo('timer');
        clearInterval(this.interval);
      }
    });
    const urlParams = new URLSearchParams(window.location.search);

    const myParam = urlParams.get('call_id');
    if (myParam) {
      this.ls.set('call_id', myParam);
      this.route.goTo('timer');
    }

    if (window.location.href.indexOf('join') > 0) {
      const call_id = window.location.href.split('join').pop();
      this.ls.set('call_id', call_id);
      this.route.goTo('timer');
    }
    this.ws._socket?.disconnect();
  }

  async ngOnInit() {
    try {
      this.dataStorage.data.apitoken =
        //   '240f17d97150e90d6c45f3bc85202b5f9d2b18d0c23765e13015da6665f191d2';
        // '1fdaedba22b1246d94a27d4ef554373f25455ae303f801605fab8c159b6971b6';
        this.tokenInfo ||
        '1fdaedba22b1246d94a27d4ef554373f25455ae303f801605fab8c159b6971b6';
      console.log(this.dataStorage.data.apitoken);
      this.route.goTo('');
    } catch (e) {
      console.log('fallo', e);
    }
  }

  // updateQueryStringParameter(uri, key, value) {
  //   var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  //   var separator = uri.indexOf('?') !== -1 ? '&' : '?';
  //   if (uri.match(re)) {
  //     return uri.replace(re, '$1' + key + '=' + value + '$2');
  //   } else {
  //     return uri + separator + key + '=' + value;
  //   }
  // }
}
