import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { WsService } from 'src/app/core/services/ws.service';
import { LoadingComponent } from 'src/app/modals/loading/loading.component';
import { TermsComponent } from 'src/app/modals/terms/terms.component';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnInit {
  name = 'Diego Armando torrealba';
  hr: string = '00';
  mn: string = '00';
  sc: string = '00';
  interval: number = 1000;
  fecha: string = '';
  intervalRef: any;
  is_time: boolean = false;
  call_id: any = undefined;
  no_hour = false;
  constructor(
    public dialog: MatDialog,
    private socket: WsService,
    private ls: LocalstorageService,
    private api: ApiService
  ) {
    this.socket.init();
    this.call_id = this.ls.get('call_id');
  }

  config() {
    this.is_time = true;
    // const now = Date.now();
    // const nextDate = Date.now() + 3;
    // const timeDiff = nextDate - now;
    // let duration: moment.Duration = moment.duration(0 * 1000, 'milliseconds');
    // moment.locale('Es-ve');
    // // this.fecha = moment(nextDate).format('DD [de] MMMM [de] yyyy hh:mm A');
    // this.setTime(duration);
    // this.intervalRef = setInterval(
    //   this.setTime((duration as any) - this.interval),
    //   this.interval
    // );
  }

  setTime(duration: moment.Duration | any) {
    return () => {
      duration = moment.duration(duration - this.interval, 'milliseconds');

      if (duration.days() > 0) {
        clearInterval(this.intervalRef);
        this.is_time = false;
        this.no_hour = true;
      }
      if (
        duration.seconds() == 0 &&
        duration.minutes() == 0 &&
        duration.hours() == 0
      ) {
        this.hr = this.mn = this.sc = '00';
        this.is_time = true;
        clearInterval(this.intervalRef);
        return;
      }

      this.hr = duration.hours().toString().padStart(2, '0');
      this.mn = duration.minutes().toString().padStart(2, '0');
      this.sc = duration.seconds().toString().padStart(2, '0');
    };
  }
  async ngOnInit() {
    if (this.call_id) {
      const data = await this.getCallInfo(this.call_id);
      localStorage.removeItem('call_id');
    } else {
      this.config();
    }
  }

  openDialog(): void {
    const termsRef = this.dialog.open(TermsComponent, {
      width: '952px',
    });

    termsRef.afterClosed().subscribe((result) => {
      // this.animal = result;
      if (result) {
        const loadingRef = this.dialog.open(LoadingComponent, {
          width: '500px',
          // height: '200px',
          disableClose: true,
        });
      }
    });
  }

  async getCallInfo(call_id) {
    try {
      const data = await this.api.get(`/ap/ev/${call_id}`).toPromise();

      this.name = data.name;
      const date = `${data.locals.date.split('/').reverse().join('-')} ${
        data.locals.time
      }:00.000-04:00`;
      console.log(date);

      this.fecha = moment
        .parseZone(moment(date))
        .locale('Es-ve')
        .format('DD [de] MMMM [de] yyyy HH:mm A');

      const now = moment(moment.now());
      const nextDate = moment(date);
      const diff = nextDate.diff(now, 'seconds');
      console.log('la mardita diferencia ', diff);

      let duration: moment.Duration = moment.duration(diff, 'seconds');
      console.log(duration.hours(), duration.minutes(), duration.seconds());

      this.intervalRef = setInterval(
        this.setTime(duration as any),
        this.interval
      );
    } catch (e) {
      console.log(e);
    }
  }
}
