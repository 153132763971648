import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from 'src/app/cores/services/cache.service';
import { ApiService } from '../services/api.service';

@Pipe({
  name: 'userid',
})
export class UseridPipe implements PipeTransform {
  constructor(private api: ApiService, private cache: CacheService) {}
  async transform(value: string, ...args: unknown[]): Promise<string> {
    try {
      if (this.cache.agentCache[value])
        return `${this.cache.agentCache[value].firstname} ${this.cache.agentCache[value].lastname}`;

      const d = await this.api.get(`/ap/user/name/${value}`).toPromise();
      this.cache.agentCache[value] = d;
      return `${d.firstname} ${d.lastname}`;
    } catch (e) {
      console.log(e);
      return value;
    }
  }
}
