import { Component, Input, OnInit } from '@angular/core';

interface ServerMessage {
  cont: string;
  type: string;
}

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
})
export class NotifyComponent implements OnInit {
  @Input('type') type: 'success' | 'info' | 'warn' = 'info';
  @Input('msg') msg: ServerMessage;

  constructor() {}

  ngOnInit(): void {}
}
