import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { interval, Observable, Subscription } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { AuthStorageService } from './auth-storage.service';
import { VlStorageService } from './vl-storage.service';
import { InternalRouterService } from '../route/internal-router.service';

@Injectable({
  providedIn: 'root',
})
export class WsService {
  _socket: Socket<any>;
  pingInterva: Subscription;
  status: EventEmitter<'connect' | 'disconnect'> = new EventEmitter<
    'connect' | 'disconnect'
  >();
  constructor(
    private storage: AuthStorageService,
    private route: InternalRouterService
  ) {}

  init() {
    let id = localStorage.getItem('id') || false;

    this._socket = io(environment.ws_url, {
      withCredentials: true,
      timeout: 20000,
      query: {
        data: JSON.stringify({
          agent: false,
          auth: {
            api_key: this.storage.API_KEY,
            token: this.storage.getToken(),
          },
          id,
          group: this.route.getQuery('gr'),
        }),
      },
    });

    this._socket.connect();

    this._socket.on('connect', () => {
      this.status.emit('connect');
      this._socket.on('id', (data) => {
        console.log(data.id);
        localStorage.setItem('id', data.id);
      });

      /*Envio de ping para mantener comunicacion abierta, reduce los tiempos de reconexion */
      this.pingInterva = interval(5000).subscribe((data) => {
        this._socket.emit('ping');
      });
    });
    this._socket.on('disconnect', () => {
      this.status.emit('disconnect');
      this.pingInterva.unsubscribe();
      console.log('desconectado');
    });
  }

  listen(event: string): Observable<any> {
    return new Observable((sub) => {
      this._socket.on(event, (data) => {
        sub.next(data);
      });
    });
  }

  emit(event: string, data: any = {}): void {
    this._socket.emit(event, data);
  }

  update(id) {
    this.emit('update', { id });
  }
}
