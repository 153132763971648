import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}

  fileUpload(
    path: string,
    file: File,
    field: string = 'image'
  ): Observable<any> {
    const formData = new FormData();
    formData.append(field, file);
    console.log(formData);

    return this.http
      .post(`${environment.storage}${path}`, formData, {
        reportProgress: true,
        observe: 'events' as 'body',
        headers: { file: 'true' },
      })
      .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
