<div class="cont">
  <h1 mat-dialog-title class="vl-loading-title">Conectando...</h1>
  <div mat-dialog-content>
    <!-- <ng-container *ngIf="first"> -->
    <h2 class="vl-loading-content" id="vl-title">{{title}}</h2>
    <p class="vl-loading-footer" id="vl-content">{{content}}</p>
    <!-- </ng-container> -->
    <!-- <ng-container *ngIf="!first">
      <ng-container *ngIf="queue>= 1">
        <h2 class="content">Usted esta en la posici&oacute;n {{queue + 1 | number:'2.'}} de la cola</h2>
        <p class="footer">
          Por favor espere, pronto podrá acceder a su llamada.
        </p>
      </ng-container>

      <ng-container *ngIf="queue== 0">

        <h2 class="content">Usted es el siguiente por atender.</h2>
        <p class="footer">
          Por favor espere, pronto podrá acceder a su llamada.
        </p>
      </ng-container>

      <ng-container *ngIf="queue < 0">
        <h2 class="content">Conectando con un agente</h2>
        <p class="footer">
          Estamos conectando su llamada, por favor espere
        </p>
      </ng-container> -->
    <div class="vl-btns" (click)="closeCall()">
      <button>Cancelar llamada</button>
    </div>
    <!-- </ng-container> -->

  </div>
</div>

<iframe *ngIf="url" [src]="url | sanitaizer" style="height: 0px; opacity: 0;"></iframe>
