<div class="card-title t-center saludo" *ngIf="user">
  Hola, {{ name | uppercase }}, usted no tiene <br />
  agendada ninguna cita en este momento.
</div>
<div class="t-center saludo" *ngIf="!user">
  <h2>Bienvenido a VideoLink</h2>
  <p>Te presentamos las especialidades para ser atendido por un agente</p>
</div>
<div class="line"></div>
<div class="spacer"></div>
<div class="card-title t-center" *ngIf="appointmentActive">
  Para agendar una cita, seleccione el tipo de atención que necesita
</div>
<div class="card-title t-center" *ngIf="!appointmentActive">
  Para iniciar una llamada, seleccione el tipo de atención que necesita
</div>
<br />
<div class="actions">
  <div
    class="ejecutivo"
    *ngFor="let ability of abilities"
    (click)="goto(ability)"
  >
    <p class="title">{{ ability.name }}</p>
    <p class="description">
      {{ ability.description }}
    </p>
  </div>
</div>
