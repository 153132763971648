// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  server_url: 'http://localhost:3003',
  ws_url: 'http://localhost:3002/videolink',
  storage: 'http://localhost:5400',
  api_token: '240f17d97150e90d6c45f3bc85202b5f9d2b18d0c23765e13015da6665f191d2', // Local bryan

  // server_url: 'https://api.qa.videolink.app',
  // ws_url: 'https://ws.qa.videolink.app/videolink',
  // storage: 'https://cdn.qa.videolink.app',
  // api_token: 'b9b83c5335b9abcb365a924fcdc5ad67cbb20c93d97729fab1ad2710ffd32b81', // Ronald corp
  // api_token: '704eb3887fd0f3c10b3fc15df3b499dd9a4b996355c38694bca2364230f1a36c', // brito
};
