import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { InternalRouterService } from 'src/app/core/route/internal-router.service';
import { VlStorageService } from 'src/app/core/services/vl-storage.service';
import { WsService } from 'src/app/core/services/ws.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  public _queue: number = 0;
  set queue(value) {
    this._queue = value;
    this.changePosition(value);
  }
  title: string = '';
  content: string = '';
  call_id: string = '';

  get queue() {
    return this._queue;
  }
  first = true;
  url: string | undefined = undefined;
  queueListSub: Subscription;
  constructor(
    public dialogRef: MatDialogRef<LoadingComponent>,
    private evaluationRef: MatDialog,
    private ws: WsService,
    private route: InternalRouterService,
    private storage: VlStorageService,
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.changePosition(0);

    this.init();
  }

  ngOnInit(): void {}

  init() {
    this.queueListSub = this.ws
      .listen('queue_list')
      .subscribe(
        (data: { data: Array<{ client_id: string; ability: string }> }) => {
          this.first = false;
          // Cola del mensaje
          // {
          //   ability: String;
          //   client_id: String
          // }
          const abilityId = this.storage.data.current.ability._id;
          const listByAbility = data.data
            .filter((ele) => ele.ability === abilityId) // Filtra los elementos por habilidad
            .map((ele) => ele.client_id); // formatea los datos

          this.queue = listByAbility.indexOf(localStorage.getItem('id')) || 0;
          // this.queue = 0;

          console.log('queue list', data);
        }
      );

    this.ws.listen('queue_resp').subscribe((data) => {
      console.log('chat_id', data.chat_id);
      localStorage.setItem('chat_id', data.chat_id);
    });

    this.ws.emit('queue', {
      action: localStorage.getItem('action'), // eliminar en el futuro
      ...this.data, // Datos del usuario,
      ability: this.storage.data.current.ability,
      group: this.route.getQuery('gr'),
    });

    this.ws
      .listen('queue_resp')
      .subscribe((data: { msg: string; chat_id: string; guest: string }) => {
        console.log('queue_resp', data);
        // this.storage.data = { ...this.storage.data, guest: data.guest };
        localStorage.setItem('guest', data.guest);
      });
    // Se une al chat de la llamada
    this.ws
      .listen('chat_join')
      .pipe(first())
      .subscribe((data) => {
        this.ws.emit('chat_join', data);
        localStorage.setItem('chat_id', data.chat_id);
        this.ws.emit('user_agent', {
          useragent: navigator.userAgent,
          chat_id: data.chat_id,
        });
        this.route.goTo('chat');
        this.dialogRef.close();
      });

    // this.ws.listen('ended_call').subscribe((data) => {
    //   if (this.call_id === data) {
    //     const dialogRef = this.evaluationRef.open(EvaluationComponent, {
    //       width: '550px',
    //       disableClose: true,
    //     });
    //     this.dialogRef.close();
    //   }
    // });
  }
  closeCall() {
    this.ws.emit('cancel_call', { chat_id: localStorage.getItem('chat_id') });
    this.route.goTo('');
    this.dialogRef.close(false);
  }

  changePosition(position: number) {
    if (this.first) {
      this.title = `Estamos registrandote en la cola`;
      this.content =
        'Por favor espere, pronto podrá acceder a su conversación.';
    } else {
      if (position > 0) {
        this.title = `Usted esta en la posicion ${this.queue
          .toString()
          .padStart(2, '0')} de la cola`;
        this.content =
          'Por favor espere, pronto podrá acceder a su conversación.';
      } else if (position == 0) {
        this.title = 'Usted es el siguiente por atender.';
        this.content =
          'Por favor espere, pronto podrá acceder a su conversación.';
      } else {
        this.title = 'Conectando con un agente';
        this.content = ' Estamos conectando su conversación, por favor espere';
      }
    }
  }
}
