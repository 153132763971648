<div class="header">
  <h2 class="title">Agendar cita</h2>
</div>

<div class="schedule">
  <div class="calendar-wrapper">
    <mat-calendar
      #calendar
      [(selected)]="selectedDate"
      (selectedChange)="dateChanged($event)"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [dateClass]="dateClass()"
    ></mat-calendar>
  </div>
  <app-time-selector
    [changeDate]="changeDateSelection"
    (selectedChange)="timeChanged($event)"
  ></app-time-selector>
</div>
<div class="cont-btn">
  <button
    mat-raised-button
    [disabled]="!(appointmentHour && appointmentDate)"
    color="banesco"
    (click)="continue()"
  >
    Continuar
  </button>
</div>
