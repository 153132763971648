import { Injectable } from '@angular/core';
import { VlStorageService } from '../services/vl-storage.service';

@Injectable({
  providedIn: 'root',
})
export class InternalRouterService {
  public currentRoute: string = '';
  constructor(private dataStorage: VlStorageService) {}

  goTo(path: string) {
    this.dataStorage.setRoute(path);
    this.currentRoute = path;
  }

  setQueryParam(query: string, value: string) {
    var searchParams = new URLSearchParams(window.location.search);
    searchParams.set(query, value);
    window.location.search = searchParams.toString();
  }

  getQuery(key: string) {
    const querys = new URLSearchParams(window.location.search);
    return querys.get(key);
  }

  removeQuery(key: string) {
    const querys = new URLSearchParams(window.location.search);
    querys.delete(key);
    console.log(querys.toString());
  }
}
